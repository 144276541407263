import { createApp } from 'vue'
import { createPinia } from 'pinia'

import * as Sentry from '@sentry/vue'

import ElementPlus from 'element-plus'

import Mega from './Mega.vue'
import router from './router'

import i18n from './i18n'

const app = createApp(Mega)
app.use(createPinia())

app.use(ElementPlus)

if (import.meta.env.VITE_ENV == 'production') {
	Sentry.init({
		app,
		dsn: 'https://81f935caefc74375b3aaaa63e538021f@o574711.ingest.us.sentry.io/5726287',
		integrations: [Sentry.replayIntegration()],
		// Session Replay
		replaysSessionSampleRate: 0.001, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	})
}

app.use(router)
app.use(i18n)
app.mount('#app')

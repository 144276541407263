import { defineStore } from 'pinia'

export const useAlertsStore = defineStore('alerts', {
	state: () => ({
		success_alert: null,
		error_alert: null
	}),
	actions: {
		success(title, message = null) {
			this.success_alert = { title: title, message: message }
		},
		error(title, message = null) {
			this.error_alert = { title: title, message: message }
		}
	}
})

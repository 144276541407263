import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '@/stores'

const router = createRouter({
	history: createWebHistory(),
	routes: [
		{
			path: '/login',
			component: () => import('@/views/auth/LoginView.vue'),
			name: 'login'
		},
		{
			path: '/auth/:provider/callback',
			component: () => import('@/views/auth/CallbackView.vue'),
			name: 'callback'
		},
		{
			path: '/dashboard',
			component: () => import('@/views/DashboardView.vue'),
			name: 'dashboard'
		},
		{
			path: '/subscriptions',
			component: () => import('@/views/SubscriptionsView.vue'),
			name: 'subscriptions'
		},
		{
			path: '/send', // Shows list of phone numbers or redirects if there's only one
			component: () => import('@/views/SendView.vue'),
			children: [
				{
					path: ':platform',
					component: () => import('@/views/SendView.vue')
				}
			]
		},
		{
			path: '/send_through', // Gets read by the Chrome extension and used as instruction to open WhatsApp
			component: () => import('@/views/SendThroughView.vue'),
			name: 'sendthrough'
		},
		{
			path: '/send_:platform_through', // Same as above but for any other platform (not changed because of dependency from extension)
			component: () => import('@/views/SendThroughView.vue'),
			name: 'sendthrough'
		},
		{
			path: '/app',
			component: () => import('@/views/App.vue'),
			name: 'app',
			children: [
				{
					path: '',
					component: () => import('@/views/ContactDealsView.vue'),
					name: 'contact_deals'
				},
				{ path: 'templates', component: () => import('@/views/TemplatesView.vue') }
			]
		},
		{
			path: '/unlock/:channel',
			component: () => import('@/views/ExtensionUnlockerView.vue'),
			name: 'extensionunlocker'
		}
		// {
		// 	path: '/_building',
		// 	component: BuildingProfiler,
		// 	name: 'building'
		// }
	]
})

router.beforeEach(async (to, from, next) => {
	const authStore = useAuthStore()

	const publicPages = ['login', 'crash']
	const authRequired = !publicPages.includes(to.name)

	if (to.name == 'callback') {
		next()
		return // Exit early to prevent further execution
	}

	// TODO: remove /dashboard
	// It's needed now because the callback is still handled by the old non-vue app, which doesn't set the localstorage user
	if (authRequired && !authStore.user && to.path != '/dashboard') {
		next('/login')
		return
	}

	if (!authRequired && authStore.user) {
		next('/app')
		return
	}

	if (to.path === '/' && authStore.user) {
		next('/app')
		return
	}

	if ((to.path === '/' || to.path === '/app') && top == window.parent) {
		// not in iframe in WhatsApp Web or Telegram Web - redirect to dashboard
		next('/dashboard')
		return
	}

	if (to.path === '/logout') {
		await authStore.logout(false) // Wait for logout to complete
		next('/login') // Then redirect to login
		return
	}

	next()
})

// Workaround for error with dynamic imports
router.onError((error, to) => {
	if (error.message.includes('Failed to fetch dynamically imported module')) {
		window.location = to.fullPath
	}
})

export default router

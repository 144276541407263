import { defineStore } from 'pinia'
import { useDealStore } from './index.js'
import router from '@/router'
import api from '@/api'

export const usePipelinesStore = defineStore('pipelines', {
	state: () => ({
		pipelines: null, // deal selected
		loading: true
	}),
	actions: {
		async getPipelines() {
			this.loading = true
			let response = await api.get('/pipelines')
			this.pipelines = response.data
			this.loading = false

			if (import.meta.env.VITE_ENV == 'local') {
				const dealStore = useDealStore()
				dealStore.selectLast()
			}
		},
		getPipelineStages(pipeline_id) {
			if (!pipeline_id || !this.pipelines?.length) {
				return []
			}
			return this.pipelines.find((pipeline) => {
				return pipeline.id == pipeline_id
			}).stages
		}
	},
	getters: {
		default_pipeline() {
			if (!this.pipelines?.length) {
				return null
			}
			return this.pipelines.find((pipeline) => {
				return pipeline.is_default
			})
		}
	}
})

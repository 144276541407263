import { defineStore } from 'pinia'
import {
	usePipelinesStore,
	useActivitiesStore,
	useDealStore,
	useTemplatesStore,
	useApplicationStore
} from './index.js'
import router from '@/router'
import api from '@/api'

export const useAuthStore = defineStore('auth', {
	state: () => ({
		user: JSON.parse(localStorage.getItem('user')),
		checked: false
	}),
	getters: {
		settings() {
			return this.user?.settings
		}
	},
	actions: {
		set(data) {
			this.user = data
			localStorage.setItem('user', JSON.stringify(this.user))
		},
		async refreshUser() {
			let response = await api.get('/auth/check')
			this.checked = true
			if (!response.data.authenticated) {
				this.forgetUser()
				return
			}
			this.set(response.data.user)
		},
		// async refreshUser() {
		// 	let response = await api.get('/user')
		// 	this.set(response.data)
		// },
		async refreshUserAndLoadApplicationData() {
			await this.refreshUser()

			if (!this.user?.can_use_app) {
				// also false if user not yet loaded
				return
			}

			const pipelinesStore = usePipelinesStore()
			const activitiesStore = useActivitiesStore()
			const dealStore = useDealStore()
			const templatesStore = useTemplatesStore()
			const applicationStore = useApplicationStore()

			pipelinesStore.getPipelines()
			dealStore.getLostReasons()
			activitiesStore.getActivityTypes()
			templatesStore.getTemplates()
		},
		getLanguage() {
			if (this.user && this.user.language) {
				return this.user.language
			}

			let language = null
			language ||= localStorage.getItem('language') // not logged in but changed language
			language ||= 'en' // fallback to english

			return language
		},
		setLanguage(language) {
			this.update({ language: language })
			localStorage.setItem('language', language)
		},
		async setSetting(key, value) {
			const data = {}
			data[key] = value
			let response = await api.put('/user/settings', data)
			this.user.settings = response.data
		},
		update(data) {
			if (this.user) {
				api.put('/user', data).then((response) => {
					this.set(response.data)
				})
			}
		},
		forgetUser() {
			this.user = null
			localStorage.removeItem('user')
		},
		async logout(redirect = true) {
			await api.get('/logout') // logout on the server

			// do the following in the frontend independent of the response
			this.forgetUser()
			if (redirect) router.push('/login')
		}
	}
})

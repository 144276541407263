import { defineStore } from 'pinia'
import { useChatStore, useContactStore, useDealStore } from '@/stores'
import api from '@/api'

export const useTemplatesStore = defineStore('templates', {
	state: () => ({
		templates: null,
		template: null, //template selected
		loading: true,
		creating: false,
		updating: false,
		fields: ['FULLNAME', 'NAME', 'FIRSTNAME', 'LASTNAME', 'ORGNAME'],

		missing_fields: []
	}),
	actions: {
		async getTemplates() {
			this.loading = true
			let response = await api.get('/templates')
			this.templates = response.data
			this.loading = false

			if (import.meta.env.VITE_ENV == 'local') {
				if (this.templates?.length) {
					// this.template = this.templates[0]
					this.template = this.templates[this.templates.length - 1]
				}
			}
		},
		async create(data) {
			return new Promise((resolve, reject) => {
				this.creating = true
				api
					.post(`/templates`, data)
					.then((response) => {
						this.templates.push(response.data)
						this.templates.sort((a, b) => {
							let t1 = a.name.toLowerCase()
							let t2 = b.name.toLowerCase()
							return t1 > t2 ? 1 : t2 > t1 ? -1 : 0
						})
						this.creating = false
						resolve(response.data)
					})
					.catch(() => {
						this.creating = false
						reject()
					})
			})
		},
		async update(data) {
			return new Promise((resolve, reject) => {
				if (!this.template) {
					return reject()
				}
				this.updating = true
				api
					.put(`/templates/${this.template.id}`, data)
					.then((response) => {
						let index = this.templates.findIndex((template) => {
							return template.id == response.data.id
						})
						this.templates[index] = response.data

						this.select(this.templates[index])
						this.updating = false
						resolve(response.data)
					})
					.catch(() => {
						this.updating = false
						reject()
					})
			})
		},
		async delete() {
			return new Promise((resolve, reject) => {
				if (!this.template) {
					return reject()
				}
				api
					.delete(`/templates/${this.template.id}`)
					.then((response) => {
						let index = this.templates.findIndex((template) => {
							return template.id == this.template.id
						})
						this.templates.splice(index, 1)
						this.select(null)
						resolve()
					})
					.catch(() => {
						reject()
					})
			})
		},
		select(id) {
			if (!id) {
				this.template = null
				return
			}
			this.template = this.templates.find((template) => {
				return template.id == id
			})
			this.missing_fields = []
		},
		hasFillValue(message, field, option1 = '', option2 = '', option3 = '') {
			let value = option1
			value ||= option2
			value ||= option3
			return value != ''
		},
		hasField(message, field) {
			return message.includes('[' + field + ']')
		},
		fieldFiller(message, field, option1 = '', option2 = '', option3 = '') {
			let value = option1
			value ||= option2
			value ||= option3
			return message.replaceAll('[' + field + ']', value)
		}
	},
	getters: {
		template_tags() {
			if (!this.template || !this.template?.tags) return []
			let duplicated = this.template.tags.trim().replaceAll(' ', ',').split(',')
			let tags = []
			duplicated.forEach((tag) => {
				if (!tags.includes(tag) && tag.trim() != '') {
					tags.push(tag)
				}
			})
			return tags
		},
		all_tags() {
			if (!this.templates?.length) return []
			let tags_strings = []
			this.templates.forEach((template) => {
				if (template.tags) {
					tags_strings.push(template.tags)
				}
			})
			if (!tags_strings.length) return []
			let duplicated = tags_strings.join(',').replaceAll(' ', ',').split(',')
			let tags = []
			duplicated.forEach((tag) => {
				if (!tags.includes(tag) && tag.trim() != '') {
					tags.push(tag)
				}
			})
			return tags
		},
		processed_template_message() {
			if (!this.template?.message) return null

			const chatStore = useChatStore()
			const contactStore = useContactStore()
			const dealStore = useDealStore()

			let message = this.template.message

			let fields_values = {
				FULLNAME: [contactStore.contact?.name, chatStore.name],
				NAME: [contactStore.contact?.first_name, chatStore.name?.split(' ')[0]],
				FIRSTNAME: [contactStore.contact?.first_name, chatStore.name?.split(' ')[0]],
				LASTNAME: [
					contactStore.contact?.last_name,
					chatStore.name?.split(' ')[chatStore.name?.split(' ')?.length - 1]
				],
				ORGNAME: [contactStore.contact?.org_name]
			}

			this.missing_fields = []

			for (const [field, values] of Object.entries(fields_values)) {
				if (this.hasField(message, field) && !this.hasFillValue(message, field, ...values)) {
					this.missing_fields.push(field)
				}
				message = this.fieldFiller(message, field, ...values)
			}

			return message
		}
	}
})

import axios from 'axios'
import Cookies from 'js-cookie'

import { useAlertsStore, useAuthStore } from './stores'

const api = axios.create({
	baseURL: '/api_v2',
	withCredentials: true,
	headers: {
		'X-CSRF-TOKEN':
			document.querySelector('meta[name="csrf-token"]').getAttribute('content')
	}
})

api.interceptors.response.use(
	(response) => {
		return response
	},
	(error) => {
		if (!error?.response?.status) {
			return Promise.reject(error)
		}

		const alertsStore = useAlertsStore()

		if (error.response.status === 419) {
			alertsStore.error('Session expired.', 'Reloading page...')
			setTimeout(() => {
				window.location.reload()
			}, 3000)
			return Promise.reject(error)
		}

		if (error.response.status === 401) {
			setTimeout(() => {
				const authStore = useAuthStore()
				authStore.logout()
			}, 3000)
		}

		let message = error?.response?.data?.message
		alertsStore.error('Something went wrong...', message)

		return Promise.reject(error)
	}
)

export default api

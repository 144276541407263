import { defineStore } from 'pinia'
import { useDealStore, useContactStore } from './index.js'

import api from '@/api'

export const useActivitiesStore = defineStore('activities', {
	state: () => ({
		activities_deal: [],
		activities_contact: [],
		activity_types: [],
		creating: false,
		loading: false
	}),
	actions: {
		async getActivities(endpoint) {
			let response = await api.get(endpoint)
			return response.data
		},
		async getActivitiesContact() {
			const contactStore = useContactStore()

			if (!contactStore.contact) {
				return
			}

			this.loading = true
			let activities = await this.getActivities(
				`/contacts/${contactStore.contact.id}/activities`
			)

			this.activities_contact = this.sortActivities(activities)
			this.loading = false
		},
		async getActivitiesDeal() {
			const dealStore = useDealStore()

			if (!dealStore.deal) {
				return
			}

			this.loading = true
			let activities = await this.getActivities(`/deals/${dealStore.deal.id}/activities`)
			this.activities_deal = this.sortActivities(activities)
			this.loading = false
		},
		async getActivityTypes() {
			let response = await api.get(`/activity_types`)
			this.activity_types = response.data
		},
		sortActivities(collection) {
			return collection.sort(function (a, b) {
				return a.date + a.time < b.date + b.time
					? -1
					: a.date + a.time > b.date + b.time
					? 1
					: 0
			})
		},
		async create(data, where) {
			return new Promise((resolve, reject) => {
				const dealStore = useDealStore()
				const contactStore = useContactStore()
				this.creating = true

				let endpoint = null

				if (where == 'deal') {
					endpoint = `/deals/${dealStore.deal.id}/activities`
				} else if (where == 'contact') {
					data.deal_id = null
					endpoint = `/contacts/${contactStore.contact.id}/activities`
				}

				api.post(endpoint, data)
					.then((response) => {
						this['activities_' + where].push(response.data)
						this.sortActivities(this['activities_' + where])
						this.creating = false
						resolve()
					})
					.catch(() => {
						this.creating = false
						reject()
					})
			})
		},
		async update(id, data) {
			return new Promise((resolve, reject) => {
				let index_deal = this.activities_deal.findIndex((activity) => {
					return activity.id == id
				})
				let index_contact = this.activities_contact.findIndex((activity) => {
					return activity.id == id
				})
				if (index_deal == -1 && index_contact == -1) {
					return reject()
				}
				if (index_deal != -1) {
					data.type = this.activities_deal[index_deal].type
				} else {
					data.type = this.activities_contact[index_contact].type
				}
				this.updating = true
				api.put(`/activities/${id}`, data)
					.then((response) => {
						if (index_deal != -1) {
							this.activities_deal[index_deal] = response.data
						}
						if (index_contact != -1) {
							this.activities_contact[index_contact] = response.data
						}
						this.updating = false
						resolve()
					})
					.catch(() => {
						this.updating = false
						reject()
					})
			})
		},
		reset() {
			this.activities_deal = []
		}
	},
	getters: {
		activities_contact_minus_already_in_deal() {
			// remove the activities that are already in this.activities_deal based on id
			return this.activities_contact.filter((activity) => {
				return !this.activities_deal.find((a) => {
					return a.id == activity.id
				})
			})
		}
	}
})

import { defineStore } from 'pinia'
import parsePhoneNumber from 'libphonenumber-js'

export const useChatStore = defineStore('chat', {
	state: () => ({
		phone: null, // phone number
		telegram_username: null, // username (used in Telegram)
		name: null, // contact name
		is_group: null,
		platform: null,
		messages: null,
		// the following are additional parameters not passed in the message from the browser extension
		phone_formatted: null // phone number
	}),
	actions: {
		set(data) {
			if (
				this.phone == data.value &&
				this.telegram_username == data.username &&
				this.name == data.name &&
				this.is_group == data.is_group &&
				this.platform == data.platform
			) {
				return false
			}

			this.phone = data.value
			this.telegram_username = data.username
			this.name = data.name
			this.is_group = data.is_group
			this.platform = data.platform

			this.phone_formatted = null
			if (this.phone) {
				this.phone_formatted = parsePhoneNumber(this.phone)?.formatInternational()
				this.phone_formatted ||= this.phone
			}

			return true
		}
	},
	getters: {
		selected_messages() {
			if (!this.messages?.length) {
				return []
			}
			return this.messages.filter((message) => message.selected)
		}
	}
})

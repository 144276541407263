<template></template>
<script>
import { useAlertsStore } from '@/stores'
import { ElNotification } from 'element-plus'

export default {
	setup() {
		const alertsStore = useAlertsStore()
		return { alertsStore }
	},
	methods: {
		alert(title, message, type = 'success') {
			ElNotification.closeAll()
			ElNotification({
				title: title,
				message: message,
				type: type,
				position: 'bottom-right',
				customClass: 'notifica',
				duration: parseInt(import.meta.env.VITE_NOTIFICATION_DURATION, 10)
			})
		}
	},
	watch: {
		alertsStore: {
			handler() {
				if (this.alertsStore.success_alert) {
					this.alert(
						this.alertsStore.success_alert.title,
						this.alertsStore.success_alert.message
					)
					this.alertsStore.success_alert = null
				}

				if (this.alertsStore.error_alert) {
					this.alert(
						this.alertsStore.error_alert.title,
						this.alertsStore.error_alert.message,
						'error'
					)
					this.alertsStore.error_alert = null
				}
			},
			deep: true
		}
	}
}
</script>

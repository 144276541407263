import { createI18n } from 'vue-i18n'

import messages from '@/locales/locales.json'

// 2. Create i18n instance with options
export default createI18n({
	locale: 'en',
	fallbackLocale: 'en',
	messages
})

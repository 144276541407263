<template>
	<MaintenanceMode />
	<RouterView v-if="!applicationStore.status.maintenance_mode" />
	<Bridge />
	<Alerts />
</template>

<script>
import { RouterLink, RouterView } from 'vue-router'
import { useAuthStore, useApplicationStore } from '@/stores'

export default {
	setup() {
		const authStore = useAuthStore()
		const applicationStore = useApplicationStore()
		return { authStore, applicationStore }
	},
	data() {
		return {
			loaded: false
		}
	},
	components: {
		RouterLink,
		RouterView
	},
	mounted() {
		this.$i18n.locale = this.authStore.getLanguage()
	},
	watch: {
		$route(to, from) {
			if (this.$route.name != 'callback' && !this.loaded) {
				this.authStore.refreshUserAndLoadApplicationData()
				this.applicationStore.pollStatus()
				this.loaded = true
			}
		}
	}
}
</script>

import { useAuthStore } from '@/stores'
import { defineStore } from 'pinia'
import api from '@/api'

export const useSubscriptionsStore = defineStore('subscriptions', {
	state: () => ({
		subscriptions: [],
		multi_seat_subscriptions: [],
		plans: [],
		loading: true,

		company_peers: [],

		ChargebeeInstance: null
	}),
	actions: {
		isEnablingOwner(subscription_id) {
			// check if a subscription is enabling the owner
			let subscription = this.subscriptions.find((subscription) => {
				return subscription.id == subscription_id
			})

			if (!['active', 'non_renewing'].includes(subscription.status)) {
				return false
			}

			let contains_owner_as_member = subscription.members
				.map((subscription) => {
					return subscription.email
				})
				.includes(useAuthStore().user.email)

			let plan = this.plans.find((plan) => {
				return plan.id == subscription.plan_id
			})

			let has_single_user_subscription = plan.cf_seats == 1 && subscription.members.length == 0

			return contains_owner_as_member || has_single_user_subscription
		},
		async load() {
			this.loading = true

			let response = await api.get('/plans')
			this.plans = response.data

			response = await api.get('/subscriptions')
			this.subscriptions = response.data

			response = await api.get('/multi_seat_subscriptions')
			this.multi_seat_subscriptions = response.data

			this.loading = false

			this.ChargebeeInstance = Chargebee.init({
				site: import.meta.env.VITE_CHARGEBEE_SITE
			})

			this.loadCompanyPeers()
		},
		async loadCompanyPeers() {
			let response = await api.get('/company_peers')
			this.company_peers = response.data
		},
		updateMembers(subscription_id, members) {
			let subscription = this.subscriptions.find((subscription) => {
				return subscription.id == subscription_id
			})
			subscription.members = members
		},
		async transfer(id, email) {
			return new Promise((resolve, reject) => {
				api
					.post(`/subscriptions/${id}/transfer`, { email })
					.then((response) => {
						resolve()
					})
					.catch(() => {
						reject()
					})
			})
		},
		async addSubscriptionMember(id, email) {
			return new Promise((resolve, reject) => {
				api
					.post(`/subscriptions/${id}/members`, { email })
					.then((response) => {
						resolve()
					})
					.catch(() => {
						reject()
					})
			})
		},
		async removeSubscriptionMember(id, email) {
			return new Promise((resolve, reject) => {
				api
					.delete(`/subscriptions/${id}/members/${email}`)
					.then((response) => {
						resolve()
					})
					.catch(() => {
						reject()
					})
			})
		},
		async cache() {
			try {
				await api.get('/cache_subscriptions')
				this.load()
			} catch (e) {}
		}
	},
	getters: {
		is_owner_enabled() {
			// check if any of the subscriptions are enabling the owner
			for (let i = 0; i < this.subscriptions.length; i++) {
				if (this.isEnablingOwner(this.subscriptions[i].id)) {
					return true
				}
			}
			return false
		},
		free_peers() {
			let taken_emails = this.subscriptions
				.map((subscription) => {
					return subscription.members.map((member) => {
						return member.email
					})
				})
				.flat()

			return this.company_peers.filter((company_peer) => {
				return !taken_emails.includes(company_peer.email)
			})
		}
	}
})

<template></template>
<script>
import { useAuthStore, useChatStore, useContactStore } from '@/stores'
// import { useContactStore } from '@/stores'

export default {
	setup() {
		const authStore = useAuthStore()
		const chatStore = useChatStore()
		const contactStore = useContactStore()
		return { authStore, chatStore, contactStore }
	},
	data() {
		return {}
	},
	methods: {},
	mounted() {
		window.addEventListener('message', (message) => {
			if (!this.authStore?.user) {
				return
			}
			switch (message.data.action) {
				case 'readCurrentNumber':
					// if returns false there is no change
					if (this.chatStore.set(message.data)) {
						this.contactStore.get(message.data)
					}
					break
				case 'readchat':
					this.chatStore.messages = message.data.value.map((message) => {
						return {
							...message,
							selected: true
						}
					})
					break
			}
		})
	}
}
</script>

import { defineStore } from 'pinia'
import api from '@/api'
import version from '@/version.json'

export const useApplicationStore = defineStore('application', {
	state: () => ({
		waiting_for_reload: false,
		current_version: version.version,
		updates_available: false,
		maintenance_mode: false,
		status: {},
		inside_extension: top != window.parent,
		query_params: JSON.parse(document.querySelector('#route_params')?.innerHTML)
	}),
	actions: {
		wait() {
			this.waiting_for_reload = true
			return new Promise((resolve) => {
				let interval = setInterval(() => {
					if (!this.waiting_for_reload) {
						clearInterval(interval)
						resolve()
					}
				}, 500)
			})
		},
		ready() {
			this.waiting_for_reload = false
		},
		async checkStatus() {
			let response = await api.get('/application')
			this.status = response.data

			if (this.maintenance_mode && !this.status.maintenance_mode) {
				window.location.reload()
			}

			this.maintenance_mode = this.status.maintenance_mode

			this.updates_available = this.status.version != this.current_version
		},
		pollStatus() {
			this.checkStatus()
			// let time = 1 * 60 * 1000
			// if (import.meta.env.VITE_ENV == 'local') {
			// 	// time = 1000 * 5
			// }
			// setInterval(() => {
			// 	this.checkStatus()
			// }, time)
		}
	}
})

import { defineStore } from 'pinia'
import { useContactStore, usePipelinesStore, useActivitiesStore, useAuthStore } from './index.js'

import api from '@/api'

export const useDealStore = defineStore('deal', {
	state: () => ({
		deal: null, // deal selected
		deals: null, // all deals for contact
		lost_reasons: null,
		loading: false,
		creating: false
	}),
	actions: {
		async getDeals() {
			const contactStore = useContactStore()

			if (!contactStore.contact) {
				return
			}
			this.loading = true
			let response = await api.get(`/contacts/${contactStore.contact.id}/deals`)
			this.deals = response.data.sort((a, b) => {
				let t1 = a.title.toLowerCase()
				let t2 = b.title.toLowerCase()
				return t1 > t2 ? 1 : t2 > t1 ? -1 : 0
			})
			this.loading = false

			if (import.meta.env.VITE_ENV == 'local') {
				// this.selectLast()
			}
		},
		async getLostReasons() {
			let response = await api.get(`/lost_reasons`)
			this.lost_reasons = response.data
		},
		select(deal, load_activities = true) {
			this.deal = deal

			if (load_activities) {
				const activitiesStore = useActivitiesStore()
				activitiesStore.getActivitiesDeal()
			}
		},
		selectFirst() {
			const pipelinesStore = usePipelinesStore()
			if (!this.deal && !pipelinesStore?.loading && this.deals?.length) {
				this.select(this.deals[0])
			}
		},
		selectLast() {
			const pipelinesStore = usePipelinesStore()
			if (!this.deal && !pipelinesStore?.loading && this.deals?.length) {
				this.select(this.deals[this.deals.length - 1])
			}
		},
		async create(data) {
			return new Promise((resolve, reject) => {
				const contactStore = useContactStore()
				this.creating = true
				api.post(`/contacts/${contactStore.contact.id}/deals`, data)
					.then((response) => {
						if (typeof this.deals === 'object') {
							this.deals.push(response.data)
						} else {
							this.deals = [response.data]
						}
						this.select(response.data)

						this.creating = false

						resolve()
					})
					.catch(() => {
						this.creating = false
						reject()
					})
			})
		},
		async update(data) {
			return new Promise((resolve, reject) => {
				if (!this.deal) {
					return reject()
				}
				this.updating = true
				api.put(`/deals/${this.deal.id}`, data)
					.then((response) => {
						let index = this.deals.findIndex((deal) => {
							return deal.id == response.data.id
						})
						this.deals[index] = response.data

						this.select(this.deals[index], false)
						this.updating = false
						resolve()
					})
					.catch(() => {
						this.updating = false
						reject()
					})
			})
		},
		reset() {
			this.deal = null
			this.deals = null
		}
	},
	getters: {
		has_deals() {
			return !this.loading && this.deals && this.deals.length
		},
		deals_ordered() {
			if (!this.deals) return this.deals

			const authStore = useAuthStore()

			// Get the order settings
			let orderSetting = authStore.settings?.deals_order
			if (!orderSetting) return this.deals

			// Parse the order settings
			let [orderProp, orderDir] = orderSetting.split(' ')

			// Sort the deals based on the settings
			let dealsCopy = [...this.deals] // create a copy to avoid mutating original array
			return dealsCopy.sort((dealA, dealB) => {
				let a = dealA[orderProp]
				let b = dealB[orderProp]

				if (typeof a === 'string') a = a.toLowerCase()
				if (typeof b === 'string') b = b.toLowerCase()

				// Compare based on the order direction
				if (orderDir === 'desc') {
					return a < b ? 1 : a > b ? -1 : 0
				} else {
					return a < b ? -1 : a > b ? 1 : 0
				}
			})
		}
	}
})
